import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Author from '../components/Author/Author'

const blogAutorTemplate = ({pageContext: { data }}) => (
  <Layout>
    <SEO title={data.name} />
    <Author authorData={ data } />
  </Layout>
)

export default blogAutorTemplate;